// src/App.js

import React, { useState } from 'react';
import './App.css';
import './ImageModal.css'; // Import the modal CSS
import ImageModal from './ImageModal'; // Import the modal component
import logo from './images/bodrum.png';
import instagramIcon from './images/instagram_icon.png'; // Add your Instagram icon image path
import facebookIcon from './images/facebook_icon.png'; // Add your Facebook icon image path
import galleryImage1 from './images/gallery1.jfif';
import galleryImage2 from './images/gallery2.jfif';
import galleryImage3 from './images/gallery3.jfif';
import galleryImage4 from './images/gallery4.jfif';
import galleryImage5 from './images/gallery5.jfif';
import galleryImage6 from './images/gallery6.jfif';
import galleryImage7 from './images/gallery7.jfif';
import galleryImage8 from './images/gallery8.jfif';
import galleryImage9 from './images/gallery9.jfif';
import galleryImage10 from './images/gallery10.jfif';
import galleryImage11 from './images/gallery11.jfif';
import galleryImage12 from './images/gallery12.jfif';
import galleryImage13 from './images/gallery13.jfif';
import galleryImage14 from './images/gallery14.jfif';
import galleryImage15 from './images/gallery15.jfif';
import galleryImage16 from './images/gallery16.jfif';
import galleryImage17 from './images/gallery17.jfif';
import galleryImage18 from './images/gallery18.jfif';
import galleryImage19 from './images/gallery19.jfif';
import galleryImage20 from './images/gallery20.jfif';
import galleryImage21 from './images/gallery21.jfif';
import galleryImage22 from './images/gallery22.jfif';
import header from './images/header.jpg'; // Import the new image

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-title">
          <img src={logo} alt="Bodrum Barbershop" className="logo" />
          <h1>Bodrum Barbershop</h1>
        </div>
        <h3 className="subheader">2a Millgate Rd, Hamilton, ML3 8JQ</h3>
        <div className="social-media">
          <a href="https://www.instagram.com/bodrumbarbershop2024/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className="social-icon" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61566551401050" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" className="social-icon" />
          </a>
        </div>
      </header>
      <section className="image-section">
        <img src={header} alt="Decorative" className="header-image" />
      </section>
      <main>
        <section id="about" className="section-block">
          <h2>About Us</h2>
          <p>Welcome to Bodrum Barber Shop, your go-to destination for traditional Turkish barbering in Hamilton. 
            We specialise in quality haircuts, precise shaves, and expert beard trims; all delivered by our experienced barbers.</p>
          <p>Join us for a genuine barbering experience that honours tradition while keeping you looking sharp.</p>
        </section>
        <section id="opening-times" className="section-block">
          <h2>Opening Times</h2>
          <p>Monday - Saturday: 9 AM - 7 PM</p>
          <p>Sunday: 10 AM - 5 PM</p>
        </section>
        <section id="photos" className="section-block">
          <h2>Photo Gallery</h2>
          <div className="gallery">
            <img src={galleryImage1} alt="Gallery 1" onClick={() => openModal(galleryImage1)} />
            <img src={galleryImage2} alt="Gallery 2" onClick={() => openModal(galleryImage2)} />
            <img src={galleryImage3} alt="Gallery 3" onClick={() => openModal(galleryImage3)} />
            <img src={galleryImage4} alt="Gallery 4" onClick={() => openModal(galleryImage4)} />
            <img src={galleryImage5} alt="Gallery 5" onClick={() => openModal(galleryImage5)} />
            <img src={galleryImage6} alt="Gallery 6" onClick={() => openModal(galleryImage6)} />
            <img src={galleryImage7} alt="Gallery 7" onClick={() => openModal(galleryImage7)} />
            <img src={galleryImage8} alt="Gallery 8" onClick={() => openModal(galleryImage8)} />
            <img src={galleryImage9} alt="Gallery 9" onClick={() => openModal(galleryImage9)} />
            <img src={galleryImage10} alt="Gallery 10" onClick={() => openModal(galleryImage10)} />
            <img src={galleryImage11} alt="Gallery 11" onClick={() => openModal(galleryImage11)} />
            <img src={galleryImage12} alt="Gallery 12" onClick={() => openModal(galleryImage12)} />
            <img src={galleryImage13} alt="Gallery 13" onClick={() => openModal(galleryImage13)} />
            <img src={galleryImage14} alt="Gallery 14" onClick={() => openModal(galleryImage14)} />
            <img src={galleryImage15} alt="Gallery 15" onClick={() => openModal(galleryImage15)} />
            <img src={galleryImage16} alt="Gallery 16" onClick={() => openModal(galleryImage16)} />
            <img src={galleryImage17} alt="Gallery 17" onClick={() => openModal(galleryImage17)} />
            <img src={galleryImage18} alt="Gallery 18" onClick={() => openModal(galleryImage18)} />
            <img src={galleryImage19} alt="Gallery 19" onClick={() => openModal(galleryImage19)} />
            <img src={galleryImage20} alt="Gallery 20" onClick={() => openModal(galleryImage20)} />
            <img src={galleryImage21} alt="Gallery 21" onClick={() => openModal(galleryImage21)} />
            <img src={galleryImage22} alt="Gallery 22" onClick={() => openModal(galleryImage22)} />
          </div>
        </section>
        <section id="contact" className="section-block">
          <h2>Contact Us</h2>
          <p>Geo: 07342 888635</p>
        </section>
      </main>
      <footer>
        <p>© 2024 Bodrum Barbershop</p>
      </footer>
      <ImageModal isOpen={isModalOpen} imageSrc={selectedImage} onClose={closeModal} />
    </div>
  );
}

export default App;