import React from 'react';
import './ImageModal.css'; // Import the modal CSS

const ImageModal = ({ isOpen, imageSrc, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <img src={imageSrc} alt="Enlarged" className="modal-image" />
      </div>
    </div>
  );
};

export default ImageModal;
